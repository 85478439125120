<template>
    <v-btn
        v-if="this.widgetMeta?.id"
        class="py-2 px-2"
        height="auto"
        text
        tile
        hover
        ripple
        target="_blank"
        rel="nofollow"
        :href="this.widgetMeta?.instant_invite ?? 'javascript:void(0)'"
        style="box-shadow: 0px 0px 1px 3px rgba(150,150,150,0.2); inset"
    >
        <!-- style="min-width: 320px; max-width: 320px;" -->
        <!-- aspect-ratio="4.5" -->
        <v-img
            class="p-0 m-0"
            :src="this.widgetImage"
            :height="this.height"
            contain
        >
        </v-img>
    </v-btn>
</template>

<script lang="ts">
import { SGuard } from '@/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface DiscordWidgetMetadata {
    id: string;
    name: string;
    instant_invite: string;
    presence_count: number;
    // members
    // channes
}

@Component
export default class DiscordBanner extends Vue {
    @Prop({
        required: true,
    })
    readonly guildId!: number;

    @Prop({
        // type: String,
        required: false,
        default: 'banner2',
    })
    readonly bannerStyle!: 'shield' | 'banner1' | 'banner2' | 'banner3' | 'banner4';

    protected widgetMeta: DiscordWidgetMetadata | null = null;
    protected widgetImage: string | null = null;

    protected get height() {
        switch (this.bannerStyle) {
            case 'shield': return 20;
            case 'banner1': return 100;
            case 'banner2': return 76;
            // case 'banner3': return 76;
            // case 'banner4': return 76;
        }
        return 100;
    }

    protected async fetchMetadata() {
        const response = await window.fetch(`https://discord.com/api/guilds/${this.guildId}/widget.json`, {
            // headers: [
            //     ['Content-type', 'application/json'],
            // ],
            // keepalive: false,
            // credentials: 'omit',
            cache: 'default',
        });
        this.widgetMeta = await response.json();
    }

    protected async fetchImage() {
        const response = await window.fetch(`https://discord.com/api/guilds/${this.guildId}/widget.png?style=${this.bannerStyle}`, {
            headers: [
                // ['Accept', 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8'],
                ['Accept', 'image/png'],
            ],
            // keepalive: false,
            credentials: 'omit',
            cache: 'default',
            window: null,
            mode: 'cors',
        });
        const blob = await response.blob();

        this.widgetImage = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (ev) {
                resolve(this.result as (string | null));
            }
            reader.onerror = function (ev) {
                reject(ev);
            };
            reader.readAsDataURL(blob);
        });
    }

    @SGuard<DiscordBanner>({
        loadContainer: function (this: DiscordBanner) {
            return this.$el;
        },
    })
    async fetch() {
        await Promise.all([
            this.fetchMetadata(),
            this.fetchImage(),
        ]);
    }

    async created() {
        await this.fetch();
    }
}
</script>

<style lang="scss">
</style>
