<template>
    <v-container>
        <h2 class="display-1 mb-3">Friends</h2>
        <!-- <v-divider class="mb-1" /> -->

        <v-card raised elevation class="mt-2 mb-8">
            <v-container>
                <v-img :src="require('@/assets/sc2mapster-logo.png')" height="48" contain/>
                <v-divider class="mt-4 mb-4" color="white" style="opacity: 0.1;"/>
                <v-layout row wrap class="py-4 justify-space-between">
                    <v-flex class="fx-item-info flex-grow-1 mx-4">
                        <p class="body-1 text-justify">
                            This place is the main hub for all activities related to StarCraft II modding, editor, tooling, help.
                        </p>
                        <v-btn
                            block
                            medium
                            outlined
                            color="primary"
                            href="https://sc2mapster.wiki.gg" rel="noopener" target="_blank"
                        >
                            Browse SC2Mapster Wiki <v-icon right medium color="primary darken-1">mdi-book-open</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex class="fx-item-discord flex-grow-1 text-right mx-2 my-2" style="min-width: 350px; max-width: 50%;">
                        <discord-banner guild-id="271701880885870594" banner-style="banner2"/>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>

        <v-card raised elevation class="mt-2 mb-8">
            <v-container>
                <v-img :src="require('@/assets/sc2pulse-logo.png')" height="48" contain/>
                <v-divider class="mt-4 mb-4" color="white" style="opacity: 0.1;"/>
                <v-layout row wrap class="py-4 justify-space-between">
                    <v-flex class="fx-item-info flex-grow-1 mx-4">
                        <p class="body-1 text-justify">
                            Cool ladder tracker for SC2. Actively developed.
                        </p>
                        <v-btn
                            block
                            medium
                            outlined
                            color="primary"
                            href="https://sc2pulse.nephest.com" rel="noopener" target="_blank"
                        >
                            Browse SC2 Pulse <v-icon right medium color="primary darken-1">fa-solid fa-up-right-from-square</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex class="fx-item-discord flex-grow-1 text-right mx-2 my-2" style="min-width: 350px; max-width: 50%;">
                        <discord-banner guild-id="1062607067766075563" banner-style="banner2"/>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DiscordBanner from '@/components/DiscordBanner.vue';

@Component({
    components: {
        DiscordBanner,
    },
})
export default class FriendsView extends Vue {
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .fx-item-info {
        max-width: 50%;
    }

    .fx-item-discord {}
}
</style>
